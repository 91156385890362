import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const Analytics = React.lazy(() => import('./Analytics'));
const Users = React.lazy(() => import('./Users'));
const CourseListing = React.lazy(() => import('./CourseListing'));
const CourseManagement = React.lazy(() => import('./CourseManagement'));
const ViewContent = React.lazy(() => import('./ViewContent'));
const Categories = React.lazy(() => import('./Categories'));
const Feedbacks = React.lazy(() => import('./Feedbacks'));
const SortingOrder = React.lazy(() => import('./SortingOrder'));

export const dashBoardConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboards/analytics',
    element: <Analytics />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboards/users',
    element: <Users />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboards/courses',
    element: <CourseListing />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboards/create',
    element: <CourseManagement />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboards/content',
    element: <ViewContent />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboards/sortCources',
    element: <SortingOrder />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboards/categories',
    element: <Categories />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboards/feedback',
    element: <Feedbacks />,
  },
];
